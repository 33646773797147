const jQuery = require('jquery');
require('jsgrid');
require('jquery-ui-bundle');

function typeIdCellRenderer(value, item) {
    if (value === 1) {
        return '<td class="jsgrid-cell">Full</td>';
    }
    else {
        return '<td class="jsgrid-cell">Part</td>';
    }
}

function sexCellRenderer(value, item) {
    return '<td class="jsgrid-cell">' + value + '</td>';
}

if (jQuery('#employeeGrid').length > 0) {
    const UrlDateField = function (config) {
        jsGrid.Field.call(this, config);
    };

    UrlDateField.prototype = new jsGrid.Field({
        css: 'date-field',
        align: 'center',

        sorter: function (date1, date2) {
            return new Date(date1) - new Date(date2);
        },

        itemTemplate: function (value, item) {
            if (value == null || value === '11/30/-0001' || value === '00/00/0000' || value === '0000-00-00') {
                return '00/00/0000';
            }
            return new Date(value).toDateString();
        },

        insertTemplate: function (value) {
            return this._insertPicker = $("<input>").datepicker({}).keyup(function(e) {
                if (e.keyCode === 8 || e.keyCode === 46) {
                    $.datepicker._clearDate(this);
                }
            });
        },

        editTemplate: function (value) {
            if (value == null || value === '11/30/-0001' || value === '00/00/0000' || value === '0000-00-00') {
                return this._editPicker = $("<input>").datepicker().datepicker("setDate", null);
            }
            return this._editPicker = $("<input>").datepicker().datepicker("setDate", new Date(value))
                .keyup(function(e) {
                    if (e.keyCode === 8 || e.keyCode === 46) {
                        $.datepicker._clearDate(this);
                    }
                });
        },

        insertValue: function () {
            let ipd = this._insertPicker.datepicker("getDate");
            if (ipd instanceof Date) {
                return this._insertPicker.datepicker("getDate").toISOString();
            }
            else {
                return '00/00/0000';
            }
        },

        editValue: function () {
            let ipd = this._editPicker.datepicker("getDate");
            if (ipd instanceof Date) {
                return this._editPicker.datepicker("getDate").toISOString();
            }
            else {
                return '00/00/0000';
            }
        }
    });

    jsGrid.fields.date = UrlDateField;

    jQuery.ajax({
        dataType: 'json',
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
        },
        url: '/api/benemployee',

    }).done((data) => {
        if (jQuery('#employeeGrid').length > 0) {
            jQuery('#employeeGrid').jsGrid({
                width: '100%',
                height: '1000px',
                filtering: true,
                inserting: true,
                editing: true,
                sorting: true,
                paging: true,
                autoload: true,

                fields: [
                    {name: 'name', title: 'Employee Name', type: 'text', width: 80},
                    {
                        name: 'type_id',
                        title: 'Type',
                        type: 'select',
                        items: [
                            {Name: 'Full'}, {Name: 'Part'}
                        ],
                        valueField: 'Name',
                        textField: 'Name',
                        valueType: 'string',
                        width: 30,
                        cellRenderer: typeIdCellRenderer
                    },
                    {name: 'ssn', title: 'Social Security Number', type: 'text', width: 50},
                    {name: 'birth_date', title: 'Birth Date', type: 'date', width: 60},
                    {name: 'address', title: 'Address', type: 'text', width: 70},
                    {name: 'city', title: 'City', type: 'text', width: 50},
                    {name: 'state', title: 'State', type: 'text', width: 30},
                    {name: 'zip', title: 'Zip', type: 'text', width: 30},
                    {
                        name: 'sex',
                        title: 'Sex',
                        type: 'select',
                        items: [
                            {Name: 'Male'}, {Name: 'Female'}
                        ],
                        valueField: 'Name',
                        textField: 'Name',
                        valueType: 'string',
                        width: 40,
                        cellRenderer: sexCellRenderer
                    },
                    {name: 'hire_date', title: 'Hire Date', type: 'date', width: 50},
                    {name: 'start_date', title: 'Start Date', type: 'date', width: 50},
                    {name: 'end_date', title: 'End Date', type: 'date', width: 50},
                    {name: 'term_date', title: 'Term Date', type: 'date', width: 50},
                    {type: 'control'}
                ],

                controller: {
                    loadData: (filter) => {
                        let d = $.Deferred();
                        jQuery.ajax({
                            type: 'GET',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/benemployee',
                            data: filter,
                            dataType: 'json'
                        })
                            .done((result) => {
                                result = $.grep(result, function (item) {
                                    return ((item.name && item.name.indexOf(filter.name) > -1)
                                        || (item.ssn && item.ssn.indexOf(filter.ssn) > -1)
                                    );
                                });
                                d.resolve(result);
                            });
                        return d.promise();
                    },
                    insertItem: (item) => {
                        return jQuery.ajax({
                            type: 'POST',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/benemployee/',
                            data: item
                        });
                    },
                    updateItem: (item) => {
                        return jQuery.ajax({
                            type: 'PUT',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/benemployee/' + item.id,
                            data: item
                        });
                    },
                    deleteItem: (item) => {
                        return jQuery.ajax({
                            type: 'DELETE',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/benemployee/' + item.id,
                            data: item
                        });
                    }
                },
            });
        }
    });
}
