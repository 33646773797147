
const doc = document;

if ($('#effective_date').length > 0 && $('#measurement_start').length > 0) {
    doc.addEventListener('DOMContentLoaded', (evt) => {
        $('#effective_date').datepicker();
        $('#enroll_for').datepicker();
        let measure_end = $('#measurement_end');
        let admin_start = $('#admin_start');
        let admin_end = $('#admin_end');
        let ongoing_start = $('#ongoing_start');
        let ongoing_end = $('#ongoing_end');


        $('#measurement_start').datepicker({
            onSelect: (mstart) => {
                let mStartDate = dayjs(mstart);
                measure_end.val(mStartDate.add(11, 'months').endOf('month').format('MM/DD/YYYY'));
            }
        });

        admin_start.datepicker({
            onSelect: (astart) => {
                let aStartDate = dayjs(astart);
                admin_end.val(aStartDate.endOf('month').format('MM/DD/YYYY'));
            }
        });

        ongoing_start.datepicker({
            onSelect: (ostart) => {
                let oStartDate = dayjs(ostart);
                ongoing_end.val(oStartDate.add(11, 'months').endOf('month').format('MM/DD/YYYY'));
            }
        })

    });
}
