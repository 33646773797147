
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('jsgrid');
require('jquery-ui-bundle');
require('./ben-settings');
require('./company-grid');
require('./company-setting');
require('./employee-grid');

const Vue = require('vue');
window.Vue = Vue;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

const dateFormat = 'yy-mm-dd';
const monthFormat = 'MM';

$('#lookback_start_date').datepicker({ dateFormat: dateFormat }).datepicker('setDate', '0');
$('#prior_lookback_start_date').datepicker({ dateFormat: dateFormat }).datepicker('setDate', '0');
$('#start_date').datepicker({ dateFormat: dateFormat });
$('#end_date').datepicker({ dateFormat: dateFormat });
