
// const dayjs = require('dayjs');
import dayjs from 'dayjs';
const doc = document;

doc.addEventListener('DOMContentLoaded', () => {
    const lstartd = doc.getElementById('lookback_start_date');
    const admin = doc.getElementById('lookback_admin_duration');
    const startd = doc.getElementById('stability_period');
    const endd = doc.getElementById('stability_period_end');
    const dur = doc.getElementById('lookback_duration');

    if (lstartd !== null && admin !== null) {
        doc.getElementById('lookback_start_date').onchange = (evt) => {
            if (lstartd !== null) {
                let startDate = dayjs(lstartd.value);
                if (startd !== null && endd !== null) {
                    startd.value = lstartd.value;
                }
            }
        }
        doc.getElementById('lookback_duration').onchange = (evt) => {
            if (admin !== null && evt.target.value === '11') {
                admin.selectedIndex = 1;
            }
            if (lstartd !== null) {
                let startDate = dayjs(lstartd.value);
                if (startd !== null && endd !== null) {
                    startd.value = lstartd.value;
                    if (admin !== null) {
                        endd.value = startDate.add(dur.value, 'month').add(admin.value, 'month').format('YYYY-MM-DD');
                    }
                    else {
                        endd.value = startDate.add(dur.value, 'month').format('YYYY-MM-DD');
                    }
                }
            }
        }
        doc.getElementById('lookback_admin_duration').onchange = (evt) => { 
            if (lstartd !== null) {
                let startDate = dayjs(lstartd.value);
                if (startd !== null && endd !== null) {
                    startd.value = lstartd.value;
                    endd.value = startDate.add(dur.value, 'month').add(evt.target.value, 'month').format('YYYY-MM-DD');
                }
            }
        }
    }
}, false);
