const jQuery = require('jquery');
require('jsgrid');
require('jquery-ui-bundle');


if (jQuery('#companyAdminGrid').length > 0) {

    jQuery.ajax({
        dataType: 'json',
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
        },
        url: '/api/bencompany',

    }).done((data) => {
        if (jQuery('#companyAdminGrid').length > 0) {
            jQuery('#companyAdminGrid').jsGrid({
                width: '100%',
                height: '800px',
                filtering: true,
                inserting: true,
                editing: true,
                sorting: true,
                paging: true,
                autoload: true,

                fields: [
                    {name: 'name', title: 'Company Name', type: 'text', width: 50},
                    {name: 'email', title: 'Contact Email Addr', type: 'text', width: 80},
                    {name: 'user_id', title: 'User ID', type: 'text', width: 50},
                    {type: 'control'}
                ],

                controller: {
                    loadData: (filter) => {
                        let d = $.Deferred();
                        jQuery.ajax({
                            type: 'GET',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/bencompany',
                            data: filter,
                            dataType: 'json'
                        })
                            .done((result) => {
                                result = $.grep(result, function (item) {
                                    return ((item.name.indexOf(filter.name) > -1)
                                        || (item.email.indexOf(filter.email) > -1)
                                    );
                                });
                                d.resolve(result);
                            });
                        return d.promise();
                    },
                    insertItem: (item) => {
                        return jQuery.ajax({
                            type: 'POST',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/bencompany',
                            data: item
                        });
                    },
                    updateItem: (item) => {
                        return jQuery.ajax({
                            type: 'PUT',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/bencompany/' + item.id,
                            data: item
                        });
                    },
                    deleteItem: (item) => {
                        return jQuery.ajax({
                            type: 'DELETE',
                            headers: {
                                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
                            },
                            url: '/api/bencompany' + item.id,
                            data: item
                        });
                    }
                },
            });
        }
    });
}
